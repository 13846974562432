import axios from 'axios';
import { formatISO } from 'date-fns';
import qs from 'qs';

const httpPictures = axios.create({
    timeout: 180000,
    paramsSerializer: params => qs.stringify(params, { serializeDate: (d) => formatISO(d) }),
    baseURL: process.env.VUE_APP_PICTURES_API_URL,
    headers: {
        Authorization: 'Bearer ' + process.env.VUE_APP_HTTP_TOKEN
    }
});

export default httpPictures;