import axios, { Canceler, AxiosResponse, AxiosError } from 'axios';
import Constants from '@/utils/constants';
import $http from '../plugins/http';
import $httpPictures from '../plugins/httpPictures';
import $httpGestor from '../plugins/httpGestor';
import { trackers } from '@/hooks/insights';

export const TEORICO_URL = '';

export type ServiceObject<R> = [Promise<R>, Canceler | null];

export const Get = <R>(url: string = '', query?: { [key: string]: any }): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

   
    const request = new Promise<R>((resolve, reject) => {
        $http.get(url, { params: query, cancelToken: new axios.CancelToken((c) => requestCanceler = c) })
            .then((resp: AxiosResponse<R>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response?.data));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);

            });
    });

    return [request, requestCanceler];
};


export const GetPictures = <R>(url: string = '', query?: { [key: string]: any }): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

   
    const request = new Promise<R>((resolve, reject) => {
        $httpPictures.get(url, { params: query, cancelToken: new axios.CancelToken((c) => requestCanceler = c) })
            .then((resp: AxiosResponse<R>) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response?.data));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);

            });
    });

    return [request, requestCanceler];
};


export const GetComplete = <R>(url: string = '', query?: { [key: string]: any }): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.get(url, { params: query, cancelToken: new axios.CancelToken((c) => requestCanceler = c) })
            .catch((error: AxiosError) => {
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);
            });
    });

    return [request, requestCanceler];
};

export const Post = <M, R>(url: string, model: M, options?: { onProgress?: (progress: number) => void; timeout?: number}): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.post<M, AxiosResponse<R>>(url, model, {
            cancelToken: new axios.CancelToken((c) => requestCanceler = c),
            onUploadProgress: (e: { type: "progress" | ""; total: number; loaded: number}) => {
                if(e.type == "progress") {
                    const percentage = Math.round((e.loaded / e.total) * 100);
                    options?.onProgress && options.onProgress(percentage);
                }
            },
            timeout: options?.timeout || 45000
        })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + "\n" + `(${error})`);
            });
    });

    return [request, requestCanceler];
};


export const Patch = <M, R>(url: string, model: M, options?: { onProgress?: (progress: number) => void; timeout?: number}): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.patch<M, AxiosResponse<R>>(url, model, {
            cancelToken: new axios.CancelToken((c) => requestCanceler = c),
            onUploadProgress: (e: { type: "progress" | ""; total: number; loaded: number}) => {
                if(e.type == "progress") {
                    const percentage = Math.round((e.loaded / e.total) * 100);
                    options?.onProgress && options.onProgress(percentage);
                }
            },
            timeout: options?.timeout || 45000
        })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);
            });
    });

    return [request, requestCanceler];
};


export const Put = <M, R>(url: string, model: M, options?: { onProgress?: (progress: number) => void; timeout?: number}): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.put<M, AxiosResponse<R>>(url, model, {
            cancelToken: new axios.CancelToken((c) => requestCanceler = c),
            onUploadProgress: (e: { type: "progress" | ""; total: number; loaded: number}) => {
                if(e.type == "progress") {
                    const percentage = Math.round((e.loaded / e.total) * 100);
                    options?.onProgress && options.onProgress(percentage);
                }
            },
            timeout: options?.timeout || 45000
        })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);
            });
    });

    return [request, requestCanceler];
};

export const Delete = <R>(url: string, options?: { onProgress?: (progress: number) => void; timeout?: number}): ServiceObject<R> => {
    const requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $http.delete(url, options)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);
            });
    });

    return [request, requestCanceler];
};

export const GetGestorSession = <R>(url: string): ServiceObject<R> => {
    const requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $httpGestor.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + `(${error})`);
            });
    });

    return [request, requestCanceler];
};

export const PostGestor = <M, R>(url: string, model: M, options?: { onProgress?: (progress: number) => void; timeout?: number}): ServiceObject<R> => {
    let requestCanceler: Canceler | null = null;

    const request = new Promise<R>((resolve, reject) => {
        $httpGestor.post<M, AxiosResponse<R>>(url, model, {
            cancelToken: new axios.CancelToken((c) => requestCanceler = c),
            onUploadProgress: (e: { type: "progress" | ""; total: number; loaded: number}) => {
                if(e.type == "progress") {
                    const percentage = Math.round((e.loaded / e.total) * 100);
                    options?.onProgress && options.onProgress(percentage);
                }
            },
            timeout: options?.timeout || 45000
        })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error: AxiosError) => {
                trackers.registerError(`Status: ${error.response?.status}`, JSON.stringify(error.response));
                reject(error.response && error.response.data && error.response.data.Error ? error.response.data.Error : Constants.DEFAULT_API_ERROR + "\n" + `(${error})`);
            });
    });

    return [request, requestCanceler];
};