import { datadogLogs } from "@datadog/browser-logs";

const config = {
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN!,
    site: process.env.VUE_APP_DATADOG_SITE!,
    env: process.env.VUE_APP_DATADOG_ENV!,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
}

datadogLogs.init(config);

const eventNames = {
    USER_ADD: "user_add",
    LOAD_AUDIT: "load_audit",
    LOAD_EXAM: "load_exam",
    STREAM_LOG: "stream_log",
    PAGE_VIEW: "page_view",
    ERROR: "error",
    EXAMINER_TRANSFERED: "examiner_transfered"
};

const registerCustomEvent = (eventName: string, properties?: { [key: string]: any }) =>
    eventName === "error" ? datadogLogs.logger.error(eventName, properties) : datadogLogs.logger.log(eventName, properties);

const trackers = {
    registerPageView: (userId: string, uri: string) => registerCustomEvent(eventNames.PAGE_VIEW, { userId, uri }),
    registerError: (userId: number | string, error: string) => registerCustomEvent(eventNames.ERROR, { name: userId, messsage: error }),
    registerUserAdd: (userId: string | number, messsage: string) => registerCustomEvent(eventNames.USER_ADD, { userId, messsage }),
    registerLoadAudit: (userId: string | number, messsage: string) => registerCustomEvent(eventNames.LOAD_AUDIT, { userId, messsage }),
    registerLoadExam: (userId: string | number, messsage: string) => registerCustomEvent(eventNames.LOAD_EXAM, { userId, messsage }),
    registerStreamLog: (streamId: string | null,
        action: 'initFailed' | 'tryPublish' | 'published' | 'lostConnection' | 'triedReconnect' | 'connectionFailed' | 'stoped' | 'error' | 'connected',
        info?: { [key: string]: any }) =>
        registerCustomEvent(eventNames.STREAM_LOG, { streamId, action, ...info }),
    registerExaminerTransfered: (userId: string, messsage: string) => registerCustomEvent(eventNames.EXAMINER_TRANSFERED, { userId, messsage })
}

export default datadogLogs;
export { trackers };